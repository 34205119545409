<template>
<div>
	<b-modal
		id="affcontrat"
		ref="my-modal"
		title="Votre contrat"
		button-size="sm"
		centered
		no-fade
		ok-only
    >
      <div class="d-block text-center">
        <h3>Contrat {{ selctrowcontrat.C_num }}</h3>
      </div>
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
				<feather-icon
					icon="LogInIcon"
					class="mr-75"
              	/>
              
              <span class="font-weight-bold">Date d'entrée</span>
            </th>
            <td class="pb-50">
              {{ selctrowcontrat.Ddateentree }}
            </td>
          </tr>
          <tr
			v-if="selctrowcontrat.B_preavis"
			>
            <th class="pb-50">
				<feather-icon
					icon="LogOutIcon"
					class="mr-75"
              	/>
              
              <span class="font-weight-bold">Préavis</span>
            </th>
            <td class="pb-50">
              Oui
            </td>
          </tr>
          <tr
			v-if="selctrowcontrat.Dsortie"
			>
            <th class="pb-50">
				<feather-icon
					icon="LogOutIcon"
					class="mr-75"
              	/>
              
              <span class="font-weight-bold">Date de sortie</span>
            </th>
            <td class="pb-50">
              {{ selctrowcontrat.Dsortie }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BoxIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Box</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ selctrowcontrat.C_box }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ShoppingCartIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Tarif</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ selctrowcontrat.I_totalttc }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="DownloadIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Dépôt de garantie</span>
            </th>
            <td class="pb-50">
              {{ selctrowcontrat.I_montandg }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ShieldIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Montant assuré</span>
            </th>
            <td class="pb-50">
              {{ selctrowcontrat.I_valassurance }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Jour de règlement</span>
            </th>
            <td>
              {{ selctrowcontrat.I_jourreg }}
            </td>
          </tr><br>
          <tr>
            <td>
              	<b-button
					v-if="!selctrowcontrat.Dsortie && !selctrowcontrat.B_demande && !selctrowcontrat.B_switch"
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					v-b-modal.modal-no-footer
					v-b-toggle.collapse-1
					variant="outline-primary"
				>
				  Demander un swith
				</b-button>
            </td>
            <td>
              	<b-button
					v-if="!selctrowcontrat.Dsortie && !selctrowcontrat.B_demande && !selctrowcontrat.B_switch"
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					v-b-modal.modal-no-footer
					v-b-toggle.collapse-2
					variant="outline-primary"
				>
				  Déclarer mon préavis
				</b-button>
            </td>
          </tr>
        </table>
		<b-collapse id="collapse-1" class="mt-2">
		  <b-card class="border mb-0">
			<b-card-text class="card-text">
			 Demander un swith
			</b-card-text>
				  <b-form-radio
					v-model="swithselected"
					name="some-radios3"
					value="1"
				  >
					Je veux un box plus grand
				  </b-form-radio>
				  <b-form-radio
					v-model="swithselected"
					name="some-radios3"
					value="2"
				  >
					Je veux un box plus petit
				  </b-form-radio><br>
			<b-button
				size="sm"
				block
				@click="addswith()"
			>
			  Valider ma demande
			</b-button>
		  </b-card>
		</b-collapse>
		<b-collapse 
			id="collapse-2" 
			class="mt-2">
		  <b-card 
			class="border mb-0">
			<b-card-text 
				class="card-text">
			 Déclarer mon préavis
			</b-card-text>
			<table class="mt-2 mt-xl-0 w-100">
			  <tr>
				<th class="pb-50">
					<feather-icon
						icon="LogInIcon"
						class="mr-75"
					/>

				  <span class="font-weight-bold">Date de sortie</span>
				</th>
				<td class="pb-50">
					<b-form-datepicker
					  v-model="D_findedite"
					  :min="min"
					  :max="max"
					  locale="fr"
					/>
				</td>
			  </tr>
			</table>
			<b-button
				v-if="D_findedite"
				size="sm"
				block
				@click="adddemande()"
			>
			  Valider ma demande
			</b-button>
		  </b-card>
		</b-collapse>
    </b-modal>
	<b-card>
		<h5>Mes contrats</h5>
		<vue-good-table
		  :columns="columns"
		  :rows="ResultContrats"
		  :pagination-options="{
			enabled: true,
			perPage:pageLength
		  }"
		  @on-row-click="onRowClickcontrat"
		>
		  <template
			slot="table-row"
			slot-scope="props"
		  >
			<span
			  v-if="props.column.field === 'C_num'"
			  class="text-nowrap"
			>
				<b-button
				v-ripple.400="'rgba(113, 102, 240, 0.15)'"
				variant="outline-primary"
				size="sm"
				@click="onRowClickcontrat"
				>
					Détail
				</b-button>
			  <span> {{ props.row.C_num }}</span>
			  <span
				v-if="props.row.B_demande"
				> (Dédite)</span>
				<span
				v-if="props.row.B_switch"
				> (Switch)</span>
			</span>
			<span v-else>
			  {{ props.formattedRow[props.column.field] }}
			</span>
		  </template>

		  <!-- pagination -->
		  <template
			slot="pagination-bottom"
			slot-scope="props"
		  >
			<div class="d-flex justify-content-between flex-wrap">
			  <div class="d-flex align-items-center mb-0 mt-1">
				<span class="text-nowrap">
				  Ligne 1 à
				</span>
				<b-form-select
				  v-model="pageLength"
				  :options="['5','10','50','100']"
				  class="mx-1"
				  @input="(value)=>props.perPageChanged({currentPerPage:value})"
				/>
				<span class="text-nowrap"> sur {{ props.total }} lignes </span>
			  </div>
			  <div>
				<b-pagination
				  :value="1"
				  :total-rows="props.total"
				  :per-page="pageLength"
				  first-number
				  last-number
				  align="right"
				  prev-class="prev-item"
				  next-class="next-item"
				  class="mt-1 mb-0"
				  @input="(value)=>props.pageChanged({currentPage:value})"
				>
				  <template #prev-text>
					<feather-icon
					  icon="ChevronLeftIcon"
					  size="18"
					/>
				  </template>
				  <template #next-text>
					<feather-icon
					  icon="ChevronRightIcon"
					  size="18"
					/>
				  </template>
				</b-pagination>
			  </div>
			</div>
		  </template>
		</vue-good-table>
	</b-card>
</div>
</template>

<script>
import {
  BCard, BPagination, BFormSelect, BModal, BButton, BCollapse, BCardText, VBToggle, BFormDatepicker, BFormRadio,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
	
export default {
	components: {
		BCard,
		BPagination,
		BFormSelect,
		BModal,
		BButton,
		BCardText,
		BCollapse,
		VueGoodTable,
		BFormDatepicker,
		BFormRadio,
	},
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
	props: {
		ResultContrats: {},
	},
	data() {
		const now = new Date()
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
		const minDate = new Date(today)
		const maxDate = new Date(today)
		minDate.setDate(minDate.getDate() + 15)
		maxDate.setMonth(minDate.getMonth() + 6)
		return {
			swithselected: '1',
			pageLength: 5,
			columns: [
				{
				  label: 'N°',
				  field: 'C_num',
				},
				{
				  label: 'Entrée',
				  field: 'Ddateentree',
				},
				{
				  label: 'Box',
				  field: 'C_box',
				},
				{
				  label: 'Tarif',
				  field: 'I_totalttc',
				},
			],
			rows: [],
			selctrowcontrat: [],
			min: minDate,
			max: maxDate,
			D_findedite: minDate,
		}
	},
	methods: {
		onRowClickcontrat(params) {
			this.selctrowcontrat = { C_num: params.row.C_num, Ddateentree: params.row.Ddateentree, C_box: params.row.C_box, I_totalttc: params.row.I_totalttc, I_montandg: params.row.I_montandg, I_jourreg: params.row.I_jourreg, Dsortie: params.row.Dsortie, I_valassurance: params.row.I_valassurance, B_preavis: params.row.B_preavis, C_UI: params.row.C_UI, B_demande: params.row.B_demande, B_switch: params.row.B_switch }
			this.$bvModal.show('affcontrat')
		},
		addswith() {
				this.$http.post('/tb/adddemande', { 
					UIC: this.selctrowcontrat.C_UI, 
					I_typeswitch: this.swithselected,
					I_type: '2',
				})
				.then(response => {
					if (response.data.res === '1') {
						this.$parent.majlst()
						this.$bvModal.hide('affcontrat')
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Demande',
								icon: 'SmileIcon',
								text: 'Demande transmise',
							},
						},
						{
							timeout: 4000,
							position: 'top-center',
						})
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Oups',
								icon: 'SmileIcon',
								variant: 'primary',
								text: 'Erreur lors du traitement',
							},
						},
						{
							timeout: 4000,
							position: 'top-center',
						})
					}	
				}) 
		},
		adddemande() {
			let berr = false
			if (!this.D_findedite) {
				berr = true	
			}
			if (!berr) {
				this.$http.post('/tb/adddemande', { 
					UIC: this.selctrowcontrat.C_UI, 
					D_date: this.D_findedite,
					I_type: '1',
				})
				.then(response => {
					if (response.data.res === '1') {
						this.$parent.majlst()
						this.$bvModal.hide('affcontrat')
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Demande',
								icon: 'SmileIcon',
								text: 'Demande transmise',
							},
						},
						{
							timeout: 4000,
							position: 'top-center',
						})
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Oups',
								icon: 'SmileIcon',
								variant: 'primary',
								text: 'Erreur lors du traitement',
							},
						},
						{
							timeout: 4000,
							position: 'top-center',
						})
					}	
				}) 
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : Merci de saisir une date',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})		
			}
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
