<template>
  <section id="dashboard-ecommerce"><br>
    <b-row class="match-height">
      <b-col
        xl="6"
        md="6"
      >
        <Tb-medal :statdata="statdata"/>
		<contrats :result-contrats="ResultContrats" />
		<demandes :result-demande="ResultDemande" />
      </b-col>
      <b-col
        xl="6"
        md="6"
      >
		<presentation />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="6"
        md="6"
      >
		<factures />	
      </b-col>
      <b-col
        xl="6"
        md="6"
      >
		<documents />
      </b-col>
    </b-row>
  </section>
</template>
<script>
import { BRow, BCol, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TbMedal from './TbMedal.vue'
import presentation from './presentation.vue'
import contrats from './contrats.vue'
import factures from './factures.vue'
import documents from './documents.vue'
import demandes from './demandes.vue'

export default {
	components: {
		BRow,
		BCol,
		TbMedal,
		presentation,
		contrats,
		factures,
		documents,
		demandes,
	},
	directives: {
		Ripple,
		'b-tooltip': VBTooltip,
	},
	data() {
		return {
			statdata: {},
			ResultDemande: [],
			ResultContrats: [],
		}
	},
	mounted() {
		this.$http.get('/tb/statboxea')
		.then(response => {
			if (response.status === 200) {
				this.statdata = response.data
			} else {
				this.$router.push('/login')	
			}
		}),
		this.$http.get('/tb/lstddemandes/19515F0D-AC9C-0812-0FCE-DEE9937C7815')
			.then(response => { this.ResultDemande = response.data.ResultDemande })
		this.$http.get('/tb/lstcontrat/19515F0D-AC9C-0812-0FCE-DEE9937C7815')
		.then(response => { this.ResultContrats = response.data.ResultContrats })
	},
	methods: {
		majlst() {
			this.ResultDemande = []
			this.ResultContrats = []
			this.$http.get('/tb/lstddemandes/19515F0D-AC9C-0812-0FCE-DEE9937C7815')
			.then(response => { this.ResultDemande = response.data.ResultDemande })
			this.$http.get('/tb/lstcontrat/19515F0D-AC9C-0812-0FCE-DEE9937C7815')
			.then(response => { this.ResultContrats = response.data.ResultContrats })
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
