<template>
	<div  style="height: 170px;" >
		<b-card
		v-if="userData"
		class="card-congratulation-medal"
		>
		<h5>Bonjour {{ userData.fullName }} 🎉 !</h5>
		<h3 class="mb-75 mt-2 pt-50">
		  <b-link
			v-if="statdata.bactif"
		  >Vous êtes client BOXEA</b-link>
		</h3>
		<b-img
			style="position: absolute;top: -20px;right : -20px;"
			v-if="statdata.bactif"
			:src="require('@/assets/images/illustration/3djump200.png')"
		/>
		</b-card>
	</div>
</template>

<script>
import {
  BCard, BLink, BImg,
} from 'bootstrap-vue'
	
export default {
	components: {
		BCard,
		BLink,
		BImg,
	},
	props: {
		statdata: {},
	},
	data() {
		return {
			userData: {},
		}
	},
	created() {
		this.userData = JSON.parse(localStorage.getItem('userData'))
	},	
}
</script>
