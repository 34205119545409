<template>
        <b-card>
		<b-img
			:src="require('@/assets/images/logo/BOXEA-LOGO.png')"
			center
		/><br>
		<b-carousel
			id="carousel-example-generic"
			controls
			indicators
			background="#ababab"
			style="text-shadow: 1px 1px 2px #333;"
		>
			<b-carousel-slide :img-src="require('@/assets/images/illustration/b1.jpg')" />
			<b-carousel-slide :img-src="require('@/assets/images/illustration/b2.jpg')" />
			<b-carousel-slide :img-src="require('@/assets/images/illustration/b4.jpg')" />
			<b-carousel-slide :img-src="require('@/assets/images/illustration/b5.jpg')" />
			<b-carousel-slide :img-src="require('@/assets/images/illustration/b6.jpg')" />
			<b-carousel-slide :img-src="require('@/assets/images/illustration/b7.jpg')" />
		</b-carousel><br>
			<b-card-text>
				<div class="text-center" ><div class="badge badge-primary" >Bienvenue chez Boxea Self Stockage.</div></div><br>
				L’espace de stockage dont vous avez besoin !<br>
				Du Box de stockage pour votre activité professionnelle au Garde-meuble pour les
				particuliers, nous avons ce qu’il Vous faut. Boxea vous propose des box de indoor (en
				intérieur) a prix abordables toute l’année.<br>
				Accessibles en autonomie 7j/7 de 05h à 22h. Stockez en toute tranquillité dans des Box
				sécurisé et dans un cadre agréable et propre.<br><br>
				<div class="text-center" ><strong>Kass pu la tête, Adopt un Box ! 😀</strong></div>
			</b-card-text>
			<b-button
			block
			Primary
			@click="openurl('https://www.boxea.fr/')"
			>
			Visiter le site
			</b-button>
        </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BImg, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
	
export default {
	components: {
		BCard,
		BCardText,
		BButton,
		BImg,
		BCarousel,
		BCarouselSlide,
	},
}
</script>
